$(document).on('turbolinks:load', function () {
  initSlick()
});

function initSlick() {
  $('.slide-nav-steps').on('click', 'a', function (e) {
    e.preventDefault();
    $('.slide-nav-steps a').removeClass("active")
    $(this).addClass("active")
    $(this).parents('section').find('.slider').slick('slickGoTo', $(this).index())
  })
  $('.slide-nav').on('click', 'a.slide-nav-prev', function (e) {
    e.preventDefault();
    $(this).parents('section').find('.slider').slick('slickPrev')
  })
  $('.slide-nav').on('click', 'a.slide-nav-next', function (e) {
    e.preventDefault();
    $(this).parents('section').find('.slider').slick('slickNext')
  })
  $(document).on('click', '.next-slide', function (e) {
    e.preventDefault();
    if ($(this).hasClass('return')) {
      $(this).parents('section').find('.slider').slick('slickGoTo', 0)
    } else {
      $(this).parents('section').find('.slider').slick('slickNext')
    }
  })

  $('.hero-slider').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    // fade: true,
    dots: false,
    draggable: true,
    arrows: false,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: false,
  });

  $('.demo-slider-refer-text').slick({
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    dots: false,
    draggable: true,
    arrows: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          adaptiveHeight: false,
        }
      },
    ]
  });

  $('.demo-slider-shop-text').slick({
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    dots: false,
    draggable: true,
    arrows: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          adaptiveHeight: false,
        }
      },
    ]
  });

  $('.demo-slider-refer').slick({
    variableWidth: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    appendDots: $(".demo-slider-dots-refer"),
    draggable: true,
    arrows: false,
    // adaptiveHeight: true,
    asNavFor: $('.demo-slider-refer-text')
  });

  $('.demo-slider-influencer').slick({
    variableWidth: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    appendDots: $(".demo-slider-dots-refer"),
    draggable: true,
    arrows: false,
    // adaptiveHeight: true,
    // asNavFor: $('.demo-slider-refer-text')
  });
  $('.demo-slider-shop').slick({
    variableWidth: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    appendDots: $(".demo-slider-dots-shop"),
    draggable: true,
    arrows: false,
    // adaptiveHeight: true,
    asNavFor: $('.demo-slider-shop-text')
  });
  $('.demo-slider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
    // console.log(nextSlide);
    // console.log(slick.slideCount);
    $(this).parents('.slides-wrapper').find('.slide-nav-steps a').removeClass("active")
    $(this).parents('.slides-wrapper').find('.slide-nav-steps a').eq(nextSlide).addClass("active")
    if (slick.slideCount == nextSlide+1) {
      $(this).parents('.slides-wrapper').find('.next-slide').addClass("return")
    } else {
      $(this).parents('.slides-wrapper').find('.next-slide').removeClass("return")
    }
  });


  $('.categories-slider').slick({
    variableWidth: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    draggable: true,
    responsive: [{
        breakpoint: 1024,
        settings: {
          centerMode: true,
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
    ]
  });


  $('.store-logos-slider').slick({
    // variableWidth: true,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    draggable: true,
    responsive: [{
        breakpoint: 1024,
        settings: {
          centerMode: true,
          slidesToShow: 4,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          centerMode: true,
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
    ]
  });

  $('.slides-to-show-5').slick({
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 5,
    dots: false,
    arrows: false,
    draggable: true,
    responsive: [{
        breakpoint: 1024,
        settings: {
          centerMode: true,
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 768,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
    ]
  });
  $('.slides-to-show-4').slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    dots: false,
    arrows: false,
    draggable: true,
    responsive: [{
        breakpoint: 1024,
        settings: {
          centerMode: true,
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 768,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
    ]
  });


  $('.slides-to-show-3').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    dots: false,
    arrows: false,
    draggable: true,
    responsive: [{
        breakpoint: 1024,
        settings: {
          centerMode: true,
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
    ]
  });

}